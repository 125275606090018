import { useState, useEffect, useRef } from 'react'
import { useLocale } from '@/providers/Locale'

type TranslationKeys =
  | '404'
  | 'accountNotifications'
  | 'accountSustainabilityBadge'
  | 'accountWebhooks'
  | 'audits'
  | 'authTemplate'
  | 'avatarGroup'
  | 'billingsPayments'
  | 'billingsTaxData'
  | 'commandPalletSearchBar'
  | 'contacts'
  | 'countries'
  | 'dashboardPage'
  | 'date'
  | 'emptyStateTable'
  | 'envelope'
  | 'envelopes'
  | 'filterDropdown'
  | 'forgotPassword'
  | 'form'
  | 'header'
  | 'headerNavigation'
  | 'headerOptionGroup'
  | 'history'
  | 'inboxPage'
  | 'profileApiTokens'
  | 'profileChangePassword'
  | 'profileGeneral'
  | 'viewEnvelope'
  | 'profileNotifications'
  | 'profilePendingInvitations'
  | 'profileSignatures'
  | 'language'
  | 'login'
  | 'modalInviteUser'
  | 'modalNoLicensedUsers'
  | 'modalPendingInvoices'
  | 'modalViewSigners'
  | 'plans'
  | 'recycleBin'
  | 'register'
  | 'reviewEnvelope'
  | 'searchVault'
  | 'seo'
  | 'sidebar'
  | 'sidebarAccount'
  | 'sidebarBilling'
  | 'sidebarProfile'
  | 'switchAccount'
  | 'table'
  | 'template'
  | 'templates'
  | 'toasts'
  | 'toggleChat'
  | 'unauthorized'
  | 'unsubscribe'
  | 'uploadAvatar'
  | 'uploadDocuments'
  | 'usersPage'
  | 'validations'
  | 'vaults'
type Schema = Record<
  TranslationKeys,
  () => Promise<{ default: Record<string, any> }>
>

const useTranslation = (
  target: TranslationKeys | TranslationKeys[],
  hasMultipleTargets?: boolean
) => {
  const { lang: langT } = useLocale()
  const lang = langT ? langT : 'PtBr'
  const [translation, setTranslation] = useState<Record<string, any>>({})
  const [isReady, setIsReady] = useState(false)
  const [lastLang, setLastLang] = useState<string | null>(null)
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true

    async function getTranslation() {
      setIsReady(false)
      try {
        const schema: Schema = {
          404: async () => import('@/translations/404'),
          accountNotifications: async () =>
            import('@/translations/accountNotifications'),
          accountSustainabilityBadge: async () =>
            import('@/translations/accountSustainabilityBadge'),
          accountWebhooks: async () => import('@/translations/accountWebhooks'),
          audits: async () => import('@/translations/audits'),
          authTemplate: async () => import('@/translations/authTemplate'),
          avatarGroup: async () => import('@/translations/avatarGroup'),
          billingsPayments: async () =>
            import('@/translations/billingsPayments'),
          billingsTaxData: async () => import('@/translations/billingsTaxData'),
          commandPalletSearchBar: async () =>
            import('@/translations/commandPalletSearchBar'),
          contacts: async () => import('@/translations/contacts'),
          countries: async () => import('@/translations/countries'),
          dashboardPage: async () => import('@/translations/dashboardPage'),
          date: async () => import('@/translations/date'),
          emptyStateTable: async () => import('@/translations/emptyStateTable'),
          envelope: async () => import('@/translations/envelope'),
          envelopes: async () => import('@/translations/envelopes'),
          filterDropdown: async () => import('@/translations/filterDropdown'),
          forgotPassword: async () => import('@/translations/forgotPassword'),
          form: async () => import('@/translations/form'),
          header: async () => import('@/translations/header'),
          headerNavigation: async () =>
            import('@/translations/headerNavigation'),
          headerOptionGroup: async () =>
            import('@/translations/headerOptionGroup'),
          history: async () => import('@/translations/history'),
          inboxPage: async () => import('@/translations/inbox'),
          profileApiTokens: async () =>
            import('@/translations/profileApiTokens'),
          profileChangePassword: async () =>
            import('@/translations/profileChangePassword'),
          profileGeneral: async () => import('@/translations/profileGeneral'),
          profileNotifications: async () =>
            import('@/translations/profileNotifications'),
          profilePendingInvitations: async () =>
            import('@/translations/profilePendingInvitations'),
          profileSignatures: async () =>
            import('@/translations/profileSignatures'),
          language: async () => import('@/translations/language'),
          login: async () => import('@/translations/login'),
          modalInviteUser: async () => import('@/translations/modalInviteUser'),
          modalNoLicensedUsers: async () =>
            import('@/translations/modalNoLicensedUsers'),
          modalPendingInvoices: async () =>
            import('@/translations/modalPendingInvoices'),
          modalViewSigners: async () =>
            import('@/translations/modalViewSigners'),
          plans: async () => import('@/translations/plans'),
          recycleBin: async () => import('@/translations/recycleBin'),
          register: async () => import('@/translations/register'),
          reviewEnvelope: async () => import('@/translations/reviewEnvelope'),
          searchVault: async () => import('@/translations/searchVault'),
          seo: async () => import('@/translations/seo'),
          sidebar: async () => import('@/translations/sidebar'),
          sidebarAccount: async () => import('@/translations/sidebarAccount'),
          sidebarBilling: async () => import('@/translations/sidebarBilling'),
          sidebarProfile: async () => import('@/translations/sidebarProfile'),
          switchAccount: async () => import('@/translations/switchAccount'),
          table: async () => import('@/translations/table'),
          template: async () => import('@/translations/template'),
          templates: async () => import('@/translations/templates'),
          toasts: async () => import('@/translations/toasts'),
          toggleChat: async () => import('@/translations/toggleChat'),
          unauthorized: async () => import('@/translations/unauthorized'),
          unsubscribe: async () => import('@/translations/unsubscribe'),
          uploadAvatar: async () => import('@/translations/uploadAvatar'),
          uploadDocuments: async () => import('@/translations/uploadDocuments'),
          usersPage: async () => import('@/translations/usersPage'),
          validations: async () => import('@/translations/validations'),
          vaults: async () => import('@/translations/vaults'),
          viewEnvelope: async () => import('@/translations/viewEnvelope')
        }

        if (hasMultipleTargets && Array.isArray(target)) {
          let final: Record<string, any> = {}

          const targets = (target as TranslationKeys[]).map((t) => schema[t]())
          const translations = await Promise.all(targets)
          translations.forEach((t) => {
            final = { ...final, ...t.default[lang] }
          })

          setTranslation(final)
        } else {
          const getTarget = schema[target as TranslationKeys]
          const item = await getTarget()
          setTranslation(item.default[lang])
        }
        setIsReady(true)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        setIsReady(false)
      }
    }

    if (
      lang !== lastLang &&
      (typeof target === 'string' || hasMultipleTargets)
    ) {
      getTranslation().then(() => {
        if (isMounted.current) {
          setLastLang(lang)
        }
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [lang, target, hasMultipleTargets, lastLang])

  return { t: translation, isReady, tLang: lastLang }
}

export default useTranslation
